import * as Types from '@/types/gqlCommon.delio.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LessPromotionsQueryVariables = Types.Exact<{
  coordinates?: Types.InputMaybe<Types.CoordinatesInput>;
}>;


export type LessPromotionsQuery = { readonly __typename: 'Query', readonly webHomeFeedPromotionSection: { readonly __typename: 'WebHomeFeedPromotionSection', readonly section?: { readonly __typename: 'PromotionsSection', readonly id: string, readonly promotions: ReadonlyArray<{ readonly __typename: 'Promotion', readonly iconUrl: string, readonly id: string, readonly key: string, readonly title: string }> } | null } };


export const LessPromotionsDocument = gql`
    query LessPromotions($coordinates: CoordinatesInput) {
  webHomeFeedPromotionSection(coordinates: $coordinates) {
    section {
      id
      promotions {
        iconUrl
        id
        key
        title
      }
    }
  }
}
    `;

/**
 * __useLessPromotionsQuery__
 *
 * To run a query within a React component, call `useLessPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessPromotionsQuery({
 *   variables: {
 *      coordinates: // value for 'coordinates'
 *   },
 * });
 */
export function useLessPromotionsQuery(baseOptions?: Apollo.QueryHookOptions<LessPromotionsQuery, LessPromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessPromotionsQuery, LessPromotionsQueryVariables>(LessPromotionsDocument, options);
      }
export function useLessPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessPromotionsQuery, LessPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessPromotionsQuery, LessPromotionsQueryVariables>(LessPromotionsDocument, options);
        }
export function useLessPromotionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LessPromotionsQuery, LessPromotionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LessPromotionsQuery, LessPromotionsQueryVariables>(LessPromotionsDocument, options);
        }
export type LessPromotionsQueryHookResult = ReturnType<typeof useLessPromotionsQuery>;
export type LessPromotionsLazyQueryHookResult = ReturnType<typeof useLessPromotionsLazyQuery>;
export type LessPromotionsSuspenseQueryHookResult = ReturnType<typeof useLessPromotionsSuspenseQuery>;
export type LessPromotionsQueryResult = Apollo.QueryResult<LessPromotionsQuery, LessPromotionsQueryVariables>;