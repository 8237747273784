import NextLink from 'next/link';
import { useTranslation } from 'next-i18next';

import { APP_ROUTES } from '@/config/routing';
import { useLessPromotionsQuery } from '@/core/graphql/queries/Promotions/LessPromotions.delio.generated';
import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { cn } from '@/core/ui/utils';

import JushLogo from '../images/jush-logo.svg';
import PromotionIcon from '../images/promotion-icon.svg';

export const PromotionsNav = () => {
  const { t } = useTranslation('layout');
  const { coordinates, loading } = useUserAddressCoordinates();
  const promotionsResult = useLessPromotionsQuery({
    variables: { coordinates },
    skip: loading,
  });
  // there's no space for more than 3 promotions
  const promotions =
    promotionsResult.data?.webHomeFeedPromotionSection.section?.promotions.slice(
      0,
      MAX_VISIBLE_PROMOTIONS
    ) ?? [];

  if (promotionsResult.error || !promotions.length) return null;

  return (
    <div className={cn('flex', 'gap-x-6', 'text-xs', 'font-medium')}>
      {promotions.map((promotion, index) => (
        <NextLink
          key={promotion.id}
          href={APP_ROUTES.promotions(promotion.key)}
          shallow
          className={cn(
            index === 0
              ? ['text-apple-red', 'hover:text-[#B46052]']
              : ['text-gray-800', 'hover:text-primary-500'],
            'flex',
            'gap-x-1',
            'items-center'
          )}
        >
          {promotion.iconUrl ? (
            <div
              className={cn(
                'w-4',
                'h-4',
                'flex',
                'items-center',
                'justify-center'
              )}
            >
              <img src={promotion.iconUrl} alt="promotionIcon" />
            </div>
          ) : (
            <PromotionIcon
              className={cn(
                'w-4',
                'h-4',
                index === 0 ? 'text-apple-red' : 'text-primary-500'
              )}
            />
          )}
          {promotion.title}
        </NextLink>
      ))}
      <NextLink
        href="/zabka-jush"
        className="flex items-center gap-x-1.5 text-gray-800 hover:text-primary-500"
      >
        <JushLogo className="size-4" />
        <div className="text-body-sm font-medium">{t('Meet Zabka Jush')}</div>
      </NextLink>
    </div>
  );
};

const MAX_VISIBLE_PROMOTIONS = 3;
